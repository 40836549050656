import React from "react";
import { ErrorSection } from "../../components";
import { Header404 } from "../../components/header";

const NotFound = () => {
  return (
    <>
      <Header404 />
      <ErrorSection />
    </>
  );
};

export default NotFound;
